/* eslint-disable */
import PropTypes from 'prop-types';
import { useEffect, useCallback, useState, useContext } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
//
import { useAuthContext } from '../hooks';
import { AuthContext } from 'src/auth/context/firebase/auth-context';
import { current } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const loginPaths = {
  jwt: paths.auth.jwt.login,
  auth0: paths.auth.auth0.login,
  amplify: paths.auth.amplify.login,
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const { user } = useContext(AuthContext);

  const { authenticated, method, isSubActive, hasAccount } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (authenticated === null) {
      return;
    }
    console.log('ACCOUNT: ' + hasAccount);
    console.log('SUB: ' + isSubActive);
    if (!hasAccount) {
      const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;
      console.log('pred router poslji v login nazaj');
      router.replace(href);
    } else if (hasAccount && !isSubActive) {
      router.replace(paths.pricing);
    } else {
      setChecked(true);

      const currentPath = router.pathname;
      // console.log("PATH: "+currentPath)
      if (user.employee && currentPath.toLowerCase() === paths.dashboard.kanban) {
        router.replace(paths.page404);
      } else if (!user.enterprise && currentPath.toLowerCase() === paths.dashboard.user.new) {
        router.replace(paths.page404);
      }
    }
  }, [authenticated, method, router, isSubActive, hasAccount]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
