/* eslint-disable */
import { m } from 'framer-motion';
import { useState, useMemo, useCallback, useEffect, useContext } from 'react';

import { ref, set, push, get, child, onValue, update, remove, off } from 'firebase/database';
import { rtdb } from 'src/auth/context/firebase/auth-provider';
import { AuthContext } from 'src/auth/context/firebase/auth-context';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
//
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'all',
    label: 'All',
    count: 22,
  },
  {
    value: 'unread',
    label: 'Unread',
    count: 12,
  },
  // {
  //   value: 'archived',
  //   label: 'Archived',
  //   count: 10,
  // },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover(notifikacije) {
  const { user } = useContext(AuthContext);
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user && user.uid) {
      setIsLoading(true);
  
      // Tracks if data has been fetched
      const dataFetched = { events: false, notifications: false, recurring: false };
  
      // Holders for the fetched data
      let eventsData = [];
      let notificationsData = [];
      let recurringData = [];

      const checkAndSetNotifications = () => {
        // Check if both data have been fetched
        if (dataFetched.events || dataFetched.notifications || dataFetched.recurring) {
          console.log(eventsData, notificationsData)
            // Combine the data only if they have been fetched
          const combinedData = [
            ...(dataFetched.events ? eventsData : []),
            ...(dataFetched.notifications ? notificationsData : []),
            ...(dataFetched.recurring ? recurringData : []),
          ];

          // Sort combined data by unifiedDate, newest first
          combinedData.sort((a, b) => new Date(b.unifiedDate) - new Date(a.unifiedDate));
          console.log("COMBINED", combinedData)

          setNotifications(combinedData);
          setIsLoading(false);
        }
      };
  
      const eventsRef = ref(rtdb, `calendar/${user.uid}`);
      onValue(eventsRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          eventsData = Object.keys(data).map(key => ({
            ...data[key],
            id: key,
            unifiedDate: data[key].start,
          })).filter(notification => {
            const notificationDate = new Date(notification.unifiedDate);
            return notification.dismiss !== true && notificationDate <= new Date();
          });
          console.log('eventsData', eventsData)
        }  else {
          eventsData = []; // Ensure it's explicitly set to an empty array
        }
        dataFetched.events = true; // Indicate that events data has been processed
        checkAndSetNotifications();
      });
  
      const notificationRef = ref(rtdb, `notifications/${user.uid}/`);
      onValue(notificationRef, (snapshot) => {
        if (snapshot.exists()) {
          const notifications = snapshot.val();
          notificationsData = Object.entries(notifications).flatMap(([userKey, markerData]) =>
            Object.entries(markerData).flatMap(([markerKey, notes]) =>
              Object.entries(notes).map(([noteKey, notification]) => ({
                ...notification,
                userKey,
                markerId: markerKey,
                noteKey,
                unifiedDate: notification.date,
              }))
            )
          );
          
          console.log('notificationsData', notificationsData)
        } else {
          notificationsData = []; // Ensure it's explicitly set to an empty array
        }
        dataFetched.notifications = true; // Indicate that notifications data has been processed
        checkAndSetNotifications();
      }, (error) => {
        console.error('Error fetching data:', error);
        setError(error);
        setIsLoading(false);
      });
  
      const recurringRef = ref(rtdb, `recurringReminders/${user.uid}`);
      onValue(recurringRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          recurringData = Object.keys(data).flatMap(markerId => {
            const markerData = data[markerId];
            return Object.keys(markerData).map(key => {
              const notification = markerData[key];
              let notificationDate = new Date(notification.date);
              const interval = notification.interval;
              let dateUpdated = false;

              // Update the date if the current date is greater or equal to the next scheduled date
              while (new Date() >= new Date(notificationDate.getTime() + interval * 24 * 60 * 60 * 1000)) {
                notificationDate.setDate(notificationDate.getDate() + interval);
                dateUpdated = true;
              }

              // Save the updated date back to the database if it was updated
              if (dateUpdated) {
                update(ref(rtdb, `recurringReminders/${user.uid}/${markerId}/${key}`), {
                  date: notificationDate.getTime(),
                  isUnRead: true,
                  isDismissed: false,
                }).then(() => {
                  console.log('Date updated for recurring reminder:', key);
                }).catch(error => {
                  console.error('Error updating date for recurring reminder:', error);
                });
              }

              return {
                ...notification,
                id: key,
                markerId,
                unifiedDate: notificationDate.getTime(),
              };
            }).filter(notification => notification.isDismissed !== true && new Date(notification.unifiedDate) <= new Date());
          });
          console.log('recurringData', recurringData)
        } else {
          recurringData = []; // Ensure it's explicitly set to an empty array
        }
        dataFetched.recurring = true; // Indicate that recurring data has been processed
        checkAndSetNotifications();
      });

      // Cleanup function to detach listeners
      return () => {
        off(eventsRef);
        off(notificationRef);
        off(recurringRef);
      };
    }
  }, [user]); // Dependency array includes `user` to re-run this effect if the user changes
  
  // console.log("DATA: "+notifications)
  // If `isUnRead` is not defined in the data, consider it to be true (unread)
  const totalUnRead = Array.isArray(notifications)
    ? notifications.filter((item) => item.isUnRead !== false).length
    : 0;

  const handleMarkAllAsRead = () => {
    if (!navigator.onLine) {
      enqueueSnackbar('No internet connection', { variant: 'error' });
      console.log('User is offline. Action aborted.');
      return;
    }
    const updatedNotifications = notifications.map((notification) => {
      const eventRef = ref(rtdb, `calendar/${user.uid}/${notification.id}`);
      const notificationRef = ref(rtdb, `notifications/${user.uid}/${notification.userKey}/${notification.markerId}/${notification.noteKey}`);
      const recurringRef = ref(rtdb, `recurringReminders/${user.uid}/${notification.markerId}/${notification.id}`);
      // Update the database
      // console.log('REF:',notification.description,notification.userKey)
      if(notification.userKey) {
        update(notificationRef, { isUnRead: false });
      } else if (notification.interval) {
        update(recurringRef, { isUnRead: false }); 
      } else {
        update(eventRef, { isUnRead: false });
      }

      return {
        ...notification,
        isUnRead: false,
      };
    });

    setNotifications(updatedNotifications);
  };

  const filteredNotifications = useMemo(() => {
    let filtered = [...notifications]; // Create a shallow copy

    if (currentTab === 'unread') {
      filtered = filtered.filter((notification) => notification.isUnRead !== false); // Only include unread notifications

      // Sort based on isUnRead. Unread notifications (isUnRead !== false) will appear first.
      filtered.sort((a, b) => {
        const aIsUnRead = a.isUnRead !== false;
        const bIsUnRead = b.isUnRead !== false;
        return aIsUnRead === bIsUnRead ? 0 : aIsUnRead ? -1 : 1;
      });
    }
    console.log("FILTERED NOTIFICATIONS:", filtered)

    return filtered;
  }, [currentTab, notifications]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const unreadCount = notifications.filter((n) => n.isUnRead !== false).length;
  const allCount = notifications.length;

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {[
        { value: 'all', label: 'All', count: allCount },
        { value: 'unread', label: 'Unread', count: unreadCount },
      ].map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {filteredNotifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            closeDrawer={drawer.onFalse}
          />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
        sx={{ bgcolor: '#ffffff' }} // Set white background color
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          {/* <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton> */}
        </Stack>

        <Divider />

        {renderList}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
