/* eslint-disable */
import PropTypes from 'prop-types';
import { useMemo, useContext, useState, useEffect } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 120px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          padding: '8px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '5%',
        },
        tableCell_2: {
          width: '20%',
        },
        tableCell_3: {
          width: '30%',
          paddingRight: 15,
        },
        tableCell_4: {
          width: '50%',
        },
        noteText: {
          fontSize: 10,
          textTransform: 'none',
        },
        boldText: {
          fontSize: 10,
          fontWeight: 700,
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function EmployeeReport({
  notes,
  displayName,
  email,
  fromDate,
  toDate,
  reportType,
}) {
  const styles = useStyles();

  // Determine the report title based on reportType
  const reportTitle = reportType === '7-Day' ? '7-Day Activity Report' : '30-Day Activity Report';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image source="/logo/markedinLogo.png" style={{ width: 200 }} />

          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>{reportTitle}</Text>
            {/* <Text> {invoiceNumber} </Text> */}
            <Text style={styles.body2}>
              {fDate(fromDate)} - {fDate(toDate)}
            </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>User:</Text>
            <Text style={styles.body2}>{displayName}</Text>
            <Text style={styles.body2}>{email}</Text>
          </View>
        </View>
        <Text style={[styles.subtitle1, styles.mb8]}>Activity Details</Text>

        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Date</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Client</Text>
              </View>

              <View style={styles.tableCell_4}>
                <Text style={styles.subtitle2}>Note</Text>
              </View>
            </View>
          </View>

          <View>
            {notes.map((note, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell_1}>
                  <Text style={styles.body1}>{index + 1}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text style={styles.noteText}>{note.date}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text style={styles.body1}>{note.placeName}</Text>
                </View>
                <View style={styles.tableCell_4}>
                  <Text style={styles.noteText}>
                    {note.subAdmin ? (
                      <Text style={styles.boldText}>{note.subAdmin}: </Text>
                    ) : (
                      note.isAdmin && <Text style={styles.boldText}>Admin: </Text>
                    )}{' '}
                    {note.text}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]} fixed>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>ACTIVITY REPORT</Text>
            <Text style={styles.body2}>
              {fDate(fromDate)} - {fDate(toDate)}
            </Text>
          </View>
          <View style={[styles.alignRight]}>
            <Text style={[styles.subtitle2, { textTransform: 'lowercase' }]}>markedin.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

EmployeeReport.propTypes = {
  notes: PropTypes.array.isRequired,
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  reportType: PropTypes.string.isRequired,
};
