import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href) => navigate(href),
      pathname: location.pathname,
      replace: (href) => navigate(href, { replace: true }),
    }),
    [navigate, location.pathname]
  );

  return router;
}
